<template>
	<b-card title="Feedback List">
		<b-row class="mb-3">

			<b-col cols="12" lg="4">
				<div class="form-group">
					<label for="name">Name:</label>
					<input 
						id="name"
						type="text" 
						class="form-control"
						placeholder="Search: name"
						v-model="filter.username" 
					>
				</div>
			</b-col>

			<b-col cols="12" lg="3">
				<div class="form-group">
					<label for="show">Jenis Feedback:</label>
					<v-select
						id="show" 
						v-model="filter.feedback_jenis_id" 
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						label="name"
						placeholder="Pilih Type"
						:options="list_feedback_type"
						:reduce="(list_feedback_type) => list_feedback_type.id"
					/>
				</div>
			</b-col>
			
			<b-col cols="12" class="mt-2">
				<button
					class="btn btn-outline-secondary mr-2"
					@click="clearFilter" 
				>
					Reset
				</button>
				<button
					class="btn btn-primary"
					v-ripple.400="'rgba(113, 102, 240, 0.15)'"
					v-b-modal.form-modal-export-csv
					@click="getDataExport">
						Export
					</button>
			</b-col>

			<Export
				:is-loading="isLoadingExport"
				:result-export="resultExport"
				:export-now="exportNow"
			/>
		</b-row>
		
		<Table 
			:result="result"
			:feedback="feedback"
			:is-loading="isLoading"
			:get-data="getData"
			:format-date="formatDate"
			:current-page="currentPage"
		/>
	</b-card>
</template>

<script>
import Export from '@/components/ExportCsv.vue'
import Table from '@/components/feedback/Table.vue'
import { successNotification, errorNotification } from '@/auth/utils'
import { 
	BRow, 
	BCol, 
	BCard,
	BTable,
	BFormInput,  
	BButton, 
	BSpinner,
	VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'
export default {
	components: {
		Export,
		Table,
		BCard,
		BTable,
		BCol,
		BRow,
		BButton,
		BSpinner,
		BFormInput,
		vSelect,
		flatPickr,
	},
	directives: {
	'b-modal': VBModal,
		Ripple,
	},
	data() {
		return {
			api:{
				feedback_type: '/admin/feedbacks-jenis',
				feedback_list: '/admin/export/feedback/list',
				feedback_export: '/admin/export/feedback/export',
				feedbacks: '/admin/feedbacks',
			},
			currentPage: 1,
			isLoading: false,
			result: [],
			feedback: {},
			list_feedback_type: [],
			link:'',
			isLoadingExport: false,
			resultExport: [],
			filter: {
				username: '',
				feedback_jenis_id: '',
			},
		}
	},
	watch: {
		filter: {
			handler: _.debounce(async function () {
				await this.getData();
			}, 300),
			deep: true,
		},
	},
	setup(props) {
		return {
			successNotification,
			errorNotification,
		}
	},
	async created() {
		await this.getData()
		await this.getFeedbackType();
	},
	methods: {
		async created() {
			await this.getData();
		},
		async getDataExport() {
			this.isLoadingExport = true
			await this.$http.get(this.api.feedback_list)
			.then(response => {
				this.resultExport = response.data.data
				this.isLoadingExport = false
			}).catch(error => {
				return errorNotification(this, 'error send export request :', error)	
			})
		},
		clearFilter() {
			this.filter = {
				username: '',
				feedback_jenis_id: '',
			}
		},
		exportNow() {
			// this.isLoadingExport = true
			const exportParam = this.filter
			exportParam.per_page = null
			this.$swal({
						title: 'Sukses',
						icon: 'success',
						text: 'Mengexport data di background, mohon tunggu beberapa saat.',
						type: 'warning',
						customClass: {
							confirmButton: 'btn btn-primary',
						},
						buttonsStyling: false,
					}) 
			this.$bvModal.hide('form-modal-export-csv')
			this.$http.post(this.api.feedback_export, exportParam).then(response => {
				if(response) {
					console.log("success")
					// this.isLoadingExport = false
				}
			}).catch(error => {
				if (error.response.data.meta.validations) {
					this.validations = error.response.data.meta.validatiosns
				}
				return errorNotification(this, 'error Export', "Terjadi Kesalah Saat Export")	
			})
		},
		async getFeedbackType(){
			await this.$http.get(this.api.feedback_type)
			.then(response => {
				this.list_feedback_type = response.data.data;
			})
			.catch(error => {
				return errorNotification(this, 'error get feedback type', error)	
			})
		},
		async getData(page) {
			this.isLoading = true
			this.currentPage = page
			const queryParams = this.filter
    		queryParams.page = page
			await this.$http.get(this.api.feedbacks, {
				params: queryParams,
			}).then(response => {
				console.log()
				this.result = response.data.data
				this.feedback = response.data.data.data
				this.currentPage = response.data.data.current_page
				this.isLoading = false
			}).catch(error => {
				const result = error.response.data.meta.validations
				for (const x in result) {
					errorNotification(this, `error ${x}`, result[x][0])
				}
			})	
		},
		formatDate(dateStr) {
			const date = new Date(dateStr);
			const options = { day: 'numeric', month: 'long', year: 'numeric' };
			return date.toLocaleDateString('id-ID', options);
		},
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>